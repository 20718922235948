import { TextField, Button, Paper, Typography } from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import loginImgSrc from "./../images/login_img.svg";
import { Link, useNavigate } from "react-router-dom";
import api from "../utils/api";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import AppLogo from "./LogoHeader";
import ErrorBox from "./ErrorBox";

interface LoginFormInput {
  email: string;
  password: string;
}

const loginFormScema = yup
  .object({
    email: yup
      .string()
      .required("Please enter your username")
      .email("Invalid Email"),
    password: yup.string().required("Please enter your password"),
  })
  .required();

export default function LoginPage() {
  const [submitting, setSubmitting] = useState(false);
  const [formError, setFormError] = useState(null);

  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (authContext.authenticated) {
      navigate("/app/clients");
    }
  }, [authContext.authenticated, navigate]);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<LoginFormInput>({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(loginFormScema),
  });

  async function signIn(data: LoginFormInput) {
    try {
      setSubmitting(true);

      const requestBody = {
        user: {
          email: data.email,
          password: data.password
        }
      };
      
      const response = await api({
        method: "POST",
        path: "users/login",
        body: requestBody,
      });
      localStorage.setItem("token", response.token);
      authContext.setAuthenticated(true);
    } catch (error: any) {
      setFormError(error.error);
      setTimeout(() => {
        setFormError(null)
      }, 3000);
    } finally {
      setSubmitting(false);
    }
  }

  const loginPageStyle: React.CSSProperties = {
    display: "flex",
    height: "100vh",
  };

  const leftHalfStyle: React.CSSProperties = {
    backgroundColor: "#FFF2F2",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    width: "50%",
    textAlign: "center",
  };

  const rightHalfStyle: React.CSSProperties = {
    backgroundColor: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
  };

  const boxStyle: React.CSSProperties = {
    width: "32rem",
    height: "32rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "1rem",
  };

  const imageStyle = {
    width: "20rem",
    height: "20rem",
  };

  const forgotPasswordContainerStyle: React.CSSProperties = {
    display: "flex",
    justifyContent: "flex-end",
    fontSize: "0.7rem",
  };

  const signInButtonStyle = {
    backgroundColor: "#E26834",
    marginTop: "2rem",
  };

  const createAccountContainerStyle = {
    display: "flex",
    marginTop: "16px",
    justifyContent: "center",
    fontSize: "0.7rem",
  };

  const footerTextStyle = {
    fontSize: "0.9rem",
    color: "darkgray",
  };

  return (
    <form onSubmit={handleSubmit(signIn)}>
      <div style={loginPageStyle}>
        <Paper elevation={0} style={leftHalfStyle}>
          <div className="flex flex-col w-full items-center">
            <img src={loginImgSrc} alt="Login" style={imageStyle} />
            <AppLogo />
            <Typography
              variant="h5"
              component="p"
              align="center"
              fontWeight="bold"
            >
              The Unified API integrations Platform
            </Typography>
          </div>
          <Typography variant="body2" component="p" style={footerTextStyle}>
            All rights reserved
          </Typography>
        </Paper>
        <div style={rightHalfStyle}>
          <Paper elevation={3} style={boxStyle}>
            <div className="flex flex-col w-[75%] gap-3">
              <Typography
                variant="h5"
                component="div"
                fontWeight="bold"
                style={{ display: "flex", marginBottom: "1rem" }}
              >
                Sign in
              </Typography>
              {formError && (
                <div style={{ marginBottom: '1.5rem' }}><ErrorBox message={formError} /></div>
              )}
              <TextField
                error={errors.email != null}
                helperText={errors.email?.message}
                label="Username"
                variant="outlined"
                fullWidth
                {...register("email")}
                placeholder="Enter your username"
              />
              <div style={forgotPasswordContainerStyle} className="mt-4">
                {/* <a href="/forgot_password" style={{ color: "#4468E8" }}>
                  Forgot Password?
                </a> */}
              </div>
              <TextField
                error={errors.password != null}
                helperText={errors.password?.message}
                label="Password"
                variant="outlined"
                type="password"
                fullWidth
                {...register("password")}
                placeholder="Enter your password"
              />
              <Button
                variant="contained"
                type="submit"
                fullWidth
                disabled={submitting}
                style={signInButtonStyle}
              >
                Sign in
              </Button>
              <div style={createAccountContainerStyle}>
                <Typography variant="subtitle2" component="p" marginRight={1}>
                  New to Firesync?
                  <Link style={{ color: "#4468E8" }} to="/demo">
                    Create an account
                  </Link>
                </Typography>
              </div>
            </div>
          </Paper>
        </div>
      </div>
    </form>
  );
}
