import {
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";

export default function ClientDropDown() {
  const options = [{
    value: "Accounting"
  }, {
    value: "ATS"
  }, {
    value: "Collaboration"
  }, {
    value: "CRM"
  }, {
    value: "HRIS"
  }, {
    value: "Ticketing"
  }]
  return (
    <FormControl sx={{ my: 1, minWidth: 250, marginBottom: 1, backgroundColor: "white", textAlign: 'left' }} size="small">
      <Select
        value={options[0].value}
      >
        {options.map((k,l) => <MenuItem value={k.value} disabled={l!==0}>{k.value}
        {l!==0 && <span style={{fontSize : 'xx-small', margin: '0 0 -6px 6px'}}>Coming Soon</span>}</MenuItem>)}
      </Select>
    </FormControl>
  );
}
