import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Drawer, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import isExcludedEmail from "../utils/excludedDomains";
import close_icon from "../images/close_icon.svg";
import api from "../utils/api";

interface ClientFormInput {
  account_name: string;
  person_name: string;
  email: string;
  phone: string;
  notes?: string;
}

declare module "yup" {
  interface StringSchema {
    isNotExcludedEmail(msg?: string): this;
  }
}

declare module "yup" {
  interface StringSchema {
    isNotExcludedEmail(msg?: string): this;
  }
}

function isNotExcludedEmail(this: yup.StringSchema, msg: string) {
  return this.test({
    name: "isNotExcludedEmail",
    message: msg,
    test: (value) => (value ? !isExcludedEmail(value) : false),
  });
}

yup.addMethod(yup.string, "isNotExcludedEmail", isNotExcludedEmail);

const clientFormSchema = yup
  .object({
    account_name: yup.string().required("Please enter client's name"),
    person_name: yup.string().required("Please enter client person's name"),
    email: yup
      .string()
      .required("Please enter client's company email")
      .isNotExcludedEmail("is an excluded email domain"),
    phone: yup.string().required("Please enter phone number"),
    notes: yup.string(),
  })
  .required();

const RightSliderForm = ({
  state,
  toggleState,
  clientObj,
  createRow,
  updateRow,
}: any) => {
  const isView = clientObj ? !clientObj.edit : false;
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<ClientFormInput>({
    defaultValues: {
      account_name: clientObj?.account_name,
      person_name: clientObj?.person_name,
      email: clientObj?.email,
      phone: clientObj?.phone,
      notes: clientObj?.notes,
    },
    resolver: yupResolver(clientFormSchema),
  });

  async function createClient(data: any) {
    toggleState(false);
    if (clientObj) updateRow(clientObj._id.$oid, data);
    else createRow(data);
  }

  const getHeader = () => {
    if (clientObj) {
      return clientObj.edit ? "Update" : "View";
    } else {
      return "Create";
    }
  };

  const onSubmit = (data: any) => createClient(data);

  return (
    <div>
      <Drawer
        anchor="right"
        open={state}
        onClose={toggleState}
        PaperProps={{ style: { width: "50%", right: 0 } }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div style={{ padding: "20px" }}>
            <div className="flex justify-between my-2">
              <div></div>
              <div style={{ fontSize: "x-large", fontWeight: 600 }}>
                {getHeader()} Client
              </div>
              <img
                src={close_icon}
                alt="close"
                onClick={() => toggleState()}
                className="cursor-pointer"
              />
            </div>
            <div>
              <TextField
                label="Client Name *"
                error={errors.account_name != null}
                helperText={errors.account_name?.message}
                {...register("account_name")}
                fullWidth
                InputProps={{
                  readOnly: isView,
                }}
                margin="normal"
                variant="outlined"
              />
              <TextField
                label="Contact Person Name"
                error={errors.person_name != null}
                helperText={errors.person_name?.message}
                {...register("person_name")}
                InputProps={{
                  readOnly: isView,
                }}
                className="w-[48%] flex"
                margin="normal"
                variant="outlined"
              />
              <div className="flex justify-between">
                <TextField
                  label="Email"
                  error={errors.email != null}
                  helperText={errors.email?.message}
                  {...register("email")}
                  InputProps={{
                    readOnly: isView,
                  }}
                  className="w-[48%]"
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  label="Phone"
                  error={errors.phone != null}
                  helperText={errors.phone?.message}
                  {...register("phone")}
                  InputProps={{
                    readOnly: isView,
                  }}
                  className="w-[48%]"
                  margin="normal"
                  variant="outlined"
                />
              </div>
              <TextField
                label="Notes"
                error={errors.notes != null}
                helperText={errors.notes?.message}
                {...register("notes")}
                multiline
                InputProps={{
                  readOnly: isView,
                }}
                rows={4}
                className="w-[48%]"
                margin="normal"
                variant="outlined"
              />
              {!isView && (
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  type="submit"
                  className="!absolute !normal-case right-5 bottom-5 !px-8 !py-3"
                >
                  Save
                </Button>
              )}
            </div>
          </div>
        </form>
      </Drawer>
    </div>
  );
};

export default RightSliderForm;
