import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Drawer, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import api from "../utils/api";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import close_icon from "../images/close_icon.svg";
import React from "react";

interface ClientFormInput {
  client_id: string;
  client_secret: string;
  callback_url: string;
}

const clientFormSchema = yup
  .object({
    client_id: yup.string().required("Please enter Client ID"),
    client_secret: yup.string().required("Please enter Client secret"),
    callback_url: yup
      .string()
      .required("Please enter Callback or Redirect URL"),
  })
  .required();

const RightSliderIntegrationForm = ({ state, toggleState, name }: any) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<ClientFormInput>({
    defaultValues: {
      client_id: "8f3a8c0c-ee73-408b-bad43451fd",
      client_secret: "bad43451fd8f3a8c0cee73408bbad43451fd8f3d43451fd8f3a8c0cee7",
      callback_url: "https://api.firesyncapi.com/quick_books/callback",
    },
    resolver: yupResolver(clientFormSchema),
  });

  async function createClient(data: any) {
    toggleState();
    // const response = await api({ path: "clients", method: "POST", body: data });
    // console.log(response);
  }

  const onSubmit = (data: any) => createClient(data);
  const [showPassword, setShowPassword] = React.useState(false);
  const handleTogglePasswordVisibility = (e: any) => {
    e.preventDefault();
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div>
      <Drawer
        anchor="right"
        open={state}
        onClose={toggleState}
        PaperProps={{ style: { width: "40%", right: 0 } }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div style={{ padding: "20px" }}>
            <div className="flex justify-between my-2">
              <div></div>
              <div style={{ fontSize: "x-large", fontWeight: 600 }}>
                Manage {name}
              </div>
              <img
                src={close_icon}
                alt="close"
                onClick={() => toggleState()}
                className="cursor-pointer"
              />
            </div>
            <div style={{display: "flex", flexDirection: "column" ,gap: "0.4rem"}}>
              <TextField
                label="Client Id"
                error={errors.client_id != null}
                helperText={errors.client_id?.message}
                {...register("client_id")}
                fullWidth
                margin="normal"
                variant="outlined"
              />
              <TextField
                label="Client Secret"
                error={errors.client_secret != null}
                helperText={errors.client_secret?.message}
                // value={formData.person_name}
                {...register("client_secret")}
                fullWidth
                margin="normal"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <button
                      onClick={handleTogglePasswordVisibility}
                      className="mr-2"
                    >
                      {showPassword ? (
                        <VisibilityOff fontSize="small" />
                      ) : (
                        <Visibility fontSize="small" />
                      )}
                    </button>
                  ),
                }}
                type={showPassword ? "text" : "password"}
              />
              <TextField
                label="Callback/Redirect URL"
                error={errors.callback_url != null}
                InputProps={{
                  readOnly: true,
                }}
                helperText={errors.callback_url?.message}
                // value={formData.notes}
                {...register("callback_url")}
                fullWidth
                margin="normal"
                variant="outlined"
              />
              <Button
                size="large"
                variant="contained"
                color="primary"
                type="submit"
                className="!absolute !normal-case right-5 bottom-5 !px-8 !py-3"
              >
                Save
              </Button>
            </div>
          </div>
        </form>
      </Drawer>
    </div>
  );
};

export default RightSliderIntegrationForm;
