import { createBrowserRouter } from "react-router-dom";
import Root from "./routes/Root";
import AppRoot from "./routes/AppRoot";
import ClientList from "./routes/ClientList";
import ClientDetail from "./routes/ClientDetail";
import Dashboard from "./routes/DashBoard";
import Login from "./routes/LogIn";
import DemoPage from "./routes/DemoPage";
import AccountingIntegration from "./routes/AccountingIntegration";
import DeveloperKey from "./routes/DeveloperKey";
import SetPasswordPage from "./routes/SetPassword";
import ForgotPasswordPage from "./routes/ForgotPassword";
import ResetPasswordPage from "./routes/ResetPassword";
import Privacy from "./policies/Privacy";
import Terms from "./policies/Terms";
import Widget from "./routes/Widget";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "demo",
    element: <DemoPage />,
  },
  {
    path: "set_password",
    element: <SetPasswordPage />,
  },
  {
    path: "reset_password",
    element: <ResetPasswordPage />,
  },
  {
    path: "forgot_password",
    element: <ForgotPasswordPage />,
  },
  {
    path: "privacy",
    element: <Privacy />,
  },
  {
    path: "terms",
    element: <Terms />,
  },
  {
    path: "widget",
    element: <Widget />,
  },
  {
    path: "/app",
    element: <AppRoot />,
    children: [
      {
        path: "clients",
        element: <ClientList />,
      },
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "integrations",
        element: <AccountingIntegration />,
      },
      {
        path: "client_detail/:id",
        element: <ClientDetail />,
      },
      {
        path: "developer_keys",
        element: <DeveloperKey />,
      },
    ],
  },
]);

export default router;
