import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import { Button } from "@mui/material";

export default function EditIntegration({ open, setOpen }: any) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
      onClick={handleClose}
    >
      <div className="bg-[#fff] p-8 rounded-2xl w-[25%] flex flex-col gap-4">
        <div className="text-[large] font-semibold text-[#333] text-left">
          Are you sure want to edit?
        </div>
        <div className="text-[small] text-left">
          <span className="text-[#F54848]">Caution: </span>
          <span className="text-[#706565]">
            Modifying the Client ID and Client Secret after setup will reset
            your settings.
          </span>
        </div>
        <div className="flex gap-4 mt-4" style={{justifyContent: "end"}}>
          <Button variant="contained" className="!normal-case rounded-lg !text-[#706565] !bg-[#EFEFEF] !px-4" size="small">Caution</Button>
          <Button variant="contained" className="!normal-case rounded-lg !px-4" size="small">Yes, Edit </Button>
        </div>
      </div>
    </Backdrop>
  );
}
