const BASE_URI = "https://api.firesyncapi.com";

export class ResponseError extends Error {
  body: object;

  constructor(m: string, body: object) {
    super(m);
    this.body = body;
  }
}

export async function parseJsonResponse(response: Response) {
  let json = null;

  try {
    json = await response.json();
  } catch (e) {
    // TODO Do something if response has no, or invalid JSON
  }

  if (response.ok) {
    if (json?.data) return json?.data;
    else return json?.status?.data;
  }

  const error = new ResponseError("server error", json);

  throw json;
}

async function api({
  path,
  body,
  method = "POST",
  headers = {},
  json = true,
}: {
  path: string;
  body?: any;
  method: string;
  headers?: HeadersInit;
  json?: boolean;
}) {
  const uri = `${BASE_URI}/${path}`;
  const token = localStorage.getItem("token");

  if (token) {
    headers = { ...headers, Authorization: `Bearer ${token}` };
  }

  // eslint-disable-next-line no-param-reassign
  headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...headers,
  };

  let params: RequestInit = {
    headers: headers,
    method: method,
  };

  if (body) {
    params.body = JSON.stringify(body);
  }

  const response = await fetch(uri, params);

  if (json) {
    return parseJsonResponse(response);
  }

  return response;
}

export default api;
