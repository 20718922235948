import React from "react";
import ClientTable from "../components/AccountingIntegrationTable";
import ClientDropDown from "./ClientDropDown";
import RightSliderIntegrationForm from "../components/IntegrationsForm";
import EditIntegration from "../components/EditModal";
import CopyClip from "../images/clip_board.svg";
import { IconButton } from "@mui/material";

export default function AccountingIntegration() {
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [appName, setAppName] = React.useState("");

  const startFrame = "<!-- Firesync badge iframe begin -->";
  const iFrameCode =
    '<iframe src="https://firesyncapi.com/widget" title="firesyncWidget" style="width: 100%; height: 100%; position: fixed; left: 0px; top: 0px;"></iframe>';
  const endFrame = "<!-- Firesync badge iframe end -->";

  const copyClipBoard = () => {
    navigator.clipboard.writeText(startFrame + iFrameCode + endFrame);
  }

  return (
    <>
      <div className="h-full ml-12 pt-8 mr-12">
        <div className="page-header text-left">
          <div className="page-main-header text-3xl font-bold">
            All Integrations
          </div>
          <div className="page-sub-header mt-4">
            Choose the integrations needed to support you clients
          </div>
        </div>

        <div className="page-action flex mt-8 relative">
          <ClientDropDown />
        </div>
        <div className="page-table mt-8 flex">
          <ClientTable
            toggleState={setOpen}
            toggleEdit={setOpenEdit}
            setName={setAppName}
          />
          <div className="w-2/5">
            <div
              className="flex gap-2.5 p-4 ml-12 rounded-[5px]"
              style={{ border: "solid 1px gray" }}
            >
              <span>Note:</span>
              <span className="text-left text-[gray]">
                You will be regularly updated on new integrations and will
                receive email notifications about any new additions, without
                incurring any additional charges for these add-ons.
              </span>
            </div>
            <div
              className="flex-col gap-2 p-4 ml-12 mt-4 rounded-[5px]"
              style={{ border: "solid 1px gray" }}
            >
              <div className="text-left">Embed:</div>
              <div className="text-left text-[gray] text-[small]">
                Inject this Iframe in your app and open it based on your
                requirement
              </div>
              <div className="mt-1 relative border bg-white text-[#8B7E7E] text-[x-small] text-left py-4 px-6 rounded-[3px] border-solid border-[#AFAFAF]">
                <IconButton className="!absolute text-[large] !right-0 !top-0" onClick={copyClipBoard}>
                  <img src={CopyClip} alt="copy" />
                </IconButton>
                <div>{startFrame}</div>
                <div>{iFrameCode}</div>
                <div>{endFrame}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RightSliderIntegrationForm
        state={open}
        toggleState={setOpen}
        name={appName}
      />
      <EditIntegration open={openEdit} setOpen={setOpenEdit} />
    </>
  );
}
