import {
  TextField,
  Button,
  Paper,
  Typography,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import logoImgSrc from "./../images/logo.svg";
import emailImgSrc from "./../images/mdi_light_email.svg";
import { Link, useNavigate } from "react-router-dom";
import api from "../utils/api";
import { useState } from "react";

interface ForgotPasswordForm {
  email: string;
}

const forgotPasswordSchema = yup
  .object({
    email: yup
      .string()
      .required("Please enter your email")
      .email("Invalid email"),
  })
  .required();

function ForgotPasswordPage() {
  const [submitting, setSubmitting] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm<ForgotPasswordForm>({
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(forgotPasswordSchema),
  });

  async function forgotPassword(data: ForgotPasswordForm) {
    try {
      setSubmitting(true);
      await api({ method: "POST", path: "password", body: data });
      setOpen(true);
    } catch (error: any) {
      setError("email", { type: "custom", message: error.body.message });
    } finally {
      setSubmitting(false);
    }
  }

  const loginPageStyle: React.CSSProperties = {
    display: "flex",
    height: "100vh",
  };

  const leftHalfStyle: React.CSSProperties = {
    backgroundColor: "#FFF2F2",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "2rem 5rem",
    width: "50%",
  };

  const rightHalfStyle: React.CSSProperties = {
    backgroundColor: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
  };

  const boxStyle: React.CSSProperties = {
    width: "32rem",
    height: "24rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "1rem",
  };

  const signInButtonStyle = {
    backgroundColor: "#E26834",
    marginTop: "2rem",
  };

  const footerTextStyle = {
    fontSize: "0.9rem",
    color: "darkgray",
  };

  const logoContainerStyle: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    marginBottom: "16px",
  };

  const logoStyle: React.CSSProperties = {
    width: "32px",
    height: "32px",
    marginRight: "8px",
  };

  const leftHalfContent: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    maxWidth: "50%",
  };

  const backToLoginContainerStyle = {
    display: "flex",
    marginTop: "16px",
    justifyContent: "center",
    fontSize: "0.7rem",
  };

  return (
    <form onSubmit={handleSubmit(forgotPassword)}>
      <div style={loginPageStyle}>
        <Paper elevation={0} style={leftHalfStyle}>
          <div className="flex flex-col w-full gap-16">
            <div style={logoContainerStyle}>
              <img src={logoImgSrc} alt="Logo" style={logoStyle} />
              <Typography
                variant="h4"
                component="h2"
                align="center"
                fontWeight="bold"
                style={{ color: "#E26834" }}
              >
                Firesync
              </Typography>
            </div>

            <div
              style={{
                display: "flex",
                height: "70vh",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={leftHalfContent}>
                <Typography
                  variant="h4"
                  component="p"
                  align="left"
                  fontWeight="bold"
                >
                  Get back to your account hassle-free!
                </Typography>

                <Typography
                  variant="body2"
                  component="p"
                  align="left"
                  style={{ marginTop: "2rem", marginBottom: "3rem" }}
                >
                  Everything's fine. Just type in your account's email, and
                  we'll quickly send you a link to reset your password.
                </Typography>
              </div>
            </div>
          </div>
          <Typography
            variant="body2"
            component="p"
            align="center"
            style={footerTextStyle}
          >
            All rights reserved
          </Typography>
        </Paper>
        <div style={rightHalfStyle}>
          <Paper elevation={3} style={boxStyle}>
            <div className="flex flex-col w-[75%] gap-3">
              <Typography
                variant="h5"
                component="div"
                fontWeight="bold"
                style={{ display: "flex", marginBottom: "2rem" }}
              >
                Forgot Password?
              </Typography>
              <TextField
                error={errors.email != null}
                helperText={errors.email?.message}
                label="Email Id"
                variant="outlined"
                fullWidth
                {...register("email")}
              />

              <Button
                variant="contained"
                type="submit"
                disabled={submitting}
                fullWidth
                style={signInButtonStyle}
              >
                Send reset link
              </Button>
              <div style={backToLoginContainerStyle}>
                <Typography
                  variant="subtitle2"
                  component="p"
                  marginRight={1}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Link style={{ color: "#4468E8" }} to="/demo">
                    <KeyboardBackspaceIcon /> Back to login
                  </Link>
                </Typography>
              </div>
            </div>
          </Paper>
        </div>
      </div>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1rem",
          }}
        >
          <img
            src={emailImgSrc}
            alt="email logo"
            style={{ height: "64px", width: "64px" }}
          />
        </div>
        <DialogTitle textAlign="center">
          <Typography
            variant="h5"
            component="p"
            fontWeight="bold"
            marginRight={1}
          >
            Reset link sent to your email!
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText textAlign="center">
            <Typography
              variant="subtitle2"
              component="p"
              marginRight={1}
              style={{ display: "flex", alignItems: "center" }}
            >
              We've sent you a password reset link. If you don't see it shortly,
              please check your spam folder.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            style={{
              ...signInButtonStyle,
              marginBottom: "2rem",
              width: "15rem",
            }}
            onClick={() => navigate("/login")}
          >
            Return to login
          </Button>
        </div>
      </Dialog>
    </form>
  );
}

export default ForgotPasswordPage;
