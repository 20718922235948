const excludedDomains = [
  "gmail",
  "yahoo",
  "ymail",
  "hotmail",
  "aol",
  "zoho",
  "outlook",
  "icloud",
  "yandex",
];

function getDomain(email: string) {
  return email.split("@")[1].split(".")[0];
}

function isExcludedEmail(email: string) {
  const emailDomain = getDomain(email);

  return excludedDomains.some((domain) => domain === emailDomain);
}

export default isExcludedEmail;
