import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import MenuItem from "@mui/material/MenuItem";
import { Menu } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";

const columns: any = [
  "Name",
  "Integration",
  "Api Requests",
  "Created At",
  "Actions",
];
const menuItems: any = ["View", "Edit", "Delete"];

const StickyHeadTable = ({
  tableData,
  toggleState,
  setClientObj,
  deleteRowAPI,
}: any) => {
  tableData = tableData.filter((o: any) => !o.show);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const navigate = useNavigate();
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const textStyle: React.CSSProperties = {
    textDecoration: "underline",
    color: "darkblue",
    cursor: "pointer",
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const editRow = (index: number) => {
    setClientObj({ ...[...tableData][index], edit: true });
    toggleState(true);
  };
  const deleteRow = (index: number) => {
    deleteRowAPI(tableData[index]._id.$oid);
  };
  const viewRow = (index: number) => {
    setClientObj({ ...[...tableData][index], edit: false });
    toggleState(true);
  };

  const [anchor, setAnchor] = React.useState(null);
  const [currentNumber, setCurrentNumber] = React.useState(-1);

  const handleOpenMenu = (event: any, number: number) => {
    setCurrentNumber(number);
    setAnchor(event.currentTarget);
  };

  const handleCloseMenu = (number: number, index: number) => {
    if (index === 0) {
      viewRow(number);
    }
    if (index === 1) {
      editRow(number);
    }
    if (index === 2) {
      deleteRow(number);
    }
    setAnchor(null);
    setCurrentNumber(-1);
  };

  const dateFormat = (d: string) => {
    const date = new Date(d);
    const actDate = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    const actMonth =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    return (
      date.getFullYear() +
      "-" +
      actMonth +
      "-" +
      actDate +
      " " +
      date.getHours() +
      ":" +
      date.getMinutes()
    );
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", borderRadius: "10px" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column: any) => (
                <TableCell
                  style={{ fontWeight: 800, textAlign: "center" }}
                  key={column}
                >
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: any, j: number) => (
                <TableRow key={j}>
                  <TableCell style={{ textAlign: "center" }}>
                    <div
                      onClick={() =>
                        navigate("/app/client_detail/" + row._id.$oid)
                      }
                    >
                      <span style={textStyle}>{row.account_name}</span>
                    </div>
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {row.integration}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {row.no_of_requests}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {dateFormat(row.created_at)}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <div>
                      <MoreVertIcon onClick={(e) => handleOpenMenu(e, j)} />
                      <Menu
                        anchorEl={anchor}
                        keepMounted
                        open={currentNumber === j}
                        onClose={() => handleCloseMenu(j, -1)}
                      >
                        {menuItems.map((q: string, h: number) => (
                          <MenuItem
                            key={q}
                            onClick={() => handleCloseMenu(j, h)}
                          >
                            {q}
                          </MenuItem>
                        ))}
                      </Menu>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={tableData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default StickyHeadTable;
