import * as React from "react";
import AccountingTable from "../components/AccountingTable";
import ClientDropDown from "./ClientDropDown";

export default function ClientDetail() {
  const handleCopyClick = () => {
    // Logic to copy the text
    // You can use the Clipboard API or a third-party library for this functionality
  };
  const [clientId] = window.location.pathname.split("/").slice(-1);
  const [accountDetails, setAccountDetails] = React.useState<any>(null);

  return (
    <>
      <div className="h-full ml-12 pt-8 mr-12">
        <div className="page-header text-left">
          <div className="page-main-header text-3xl font-bold">
            Client Details
          </div>
          <div className="page-sub-header mt-4">
            Manage integrations of all your clients
          </div>
        </div>
        <div className="page-action flex mt-8 relative">
          <ClientDropDown />
        </div>
        <div className="page-content flex mt-8">
          <div className="page-table w-[70%]">
            <AccountingTable details={setAccountDetails}/>
          </div>

          <div className="text-fields ml-8 w-[30%]">
            <span className="block text-left text-slate-500 text-[small]">
              Client Name
            </span>
            <span className="block text-left">{accountDetails?.account_name}</span>
            <span className="block text-left text-slate-500 mt-4 text-[small]">
              Email Address
            </span>
            <span className="block text-left">{accountDetails?.email}</span>
            <span className="block text-left text-slate-500 mt-4 text-[small]">
              Tally Client Key
            </span>
            <span className="block text-left">{clientId}</span>
            {/* <span className="block text-left text-slate-500 mt-4 text-[small]">Client id</span>
          <span className="block text-left text-slate-500 mt-2">
            <TextField
              variant="outlined"
              size="small"
              defaultValue="Instacard Inc"
              fullWidth
              style={{backgroundColor: '#fff'}}
              InputProps={{
                endAdornment: (
                  <button onClick={handleCopyClick} style={textFiledButton}>
                    Save
                  </button>
                ),
              }}
            />
          </span>
          <span className="block text-left text-slate-500 mt-4 text-[small]">
            Client secret
          </span>
          <span className="block text-left text-slate-500 mt-2">
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              style={{backgroundColor: '#fff'}}
              defaultValue="Instacard Inc"
              InputProps={{
                startAdornment: (
                  <button onClick={handleTogglePasswordVisibility} className="mr-2">
                    {showPassword ? (
                      <VisibilityOff fontSize="small" />
                    ) : (
                      <Visibility fontSize="small" />
                    )}
                  </button>
                ),
                endAdornment: (
                  <button onClick={handleCopyClick} style={textFiledButton}>
                    Save
                  </button>
                ),
              }}
              type={showPassword ? "text" : "password"}
            />
          </span>
          <span className="block text-left text-slate-500 mt-4 text-[small]">
            Callback URL
          </span>
          <span className="block text-left text-slate-500 mt-2">
            <TextField
              variant="outlined"
              size="small"
              value="https://firesync.api/com"
              fullWidth
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <button onClick={handleCopyClick} style={textFiledButton}>
                    Copy
                  </button>
                ),
              }}
            />
          </span> */}
          </div>
        </div>
      </div>

    </>
  );
}
