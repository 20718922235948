import { NavLink, Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import DashBoardIcon from "../images/nav-icons/dashboard.svg";
import ClientIcon from "../images/nav-icons/clients.svg";
import IntegrationsIcon from "../images/nav-icons/integrations.svg";
import ClinetDetailsIcon from "../images/nav-icons/client-details.svg";
import DeveloperKeysIcon from "../images/nav-icons/developer-keys.svg";
import NotificationsIcon from "../images/bottom-nav/notifications.svg";
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined';
import HelpIcon from "../images/bottom-nav/help.svg";
import ProfileIcon from "../images/bottom-nav/profile.svg";
import logoSrc from "./../images/main_logo.svg";
import LogoutIcon from "../images/bottom-nav/logout.svg";
import "./styles.css";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
export default function Sidebar() {
  const authContext = useContext(AuthContext);
  return (
    <div className="border-r-2 h-full justify-between px-0 py-8 flex flex-col items-center">
      <div>
        <div>
          <img src={logoSrc} alt="logo" className="h-12"/>
        </div>
        <div className="actions flex flex-col mt-8 gap-3">
          <Tooltip title="Dashboard" placement="right">
            <NavLink to={"dashboard"} className="p-2 sidebar-icon rounded">
              <img src={DashBoardIcon} alt="Dashboard" />
            </NavLink>
          </Tooltip>
          <Tooltip title="Clients" placement="right">
            <NavLink to={"clients"} className="p-2 sidebar-icon rounded">
              <img src={ClientIcon} alt="Clients" />
            </NavLink>
          </Tooltip>
          <Tooltip title="Integrations" placement="right">
            <NavLink to={"integrations"} className="p-2 sidebar-icon rounded">
              <img src={IntegrationsIcon} alt="Clients" />
            </NavLink>
          </Tooltip>
          {/* <Tooltip title="Client Details" placement="right">
            <NavLink to={"client_detail"} className="p-2 sidebar-icon rounded">
              <img src={ClinetDetailsIcon} alt="Clients" />
            </NavLink>
          </Tooltip> */}
          <Tooltip title="Developer keys" placement="right">
            <NavLink to={"developer_keys"} className="p-2 sidebar-icon rounded">
              <img src={DeveloperKeysIcon} alt="Clients" />
            </NavLink>
          </Tooltip>
          <Tooltip title="API Integration" placement="right">
            <NavLink to="https://firesync.readme.io/reference/getting-started-with-your-api" target="_blank" rel="noopener noreferrer" className="p-2 sidebar-icon rounded">
              <IntegrationInstructionsOutlinedIcon className="!text-3xl"/>
            </NavLink>
          </Tooltip>
        </div>
      </div>
      <div className="footerActions flex flex-col gap-1">
        {/* <Link to={""} className="p-2 sidebar-icon rounded">
          <img src={NotificationsIcon} alt="Notification" />
        </Link>
        <Link to={""} className="p-2 sidebar-icon rounded">
          <img src={HelpIcon} alt="Help" />
        </Link>
        <Link to={""} className="p-2 sidebar-icon rounded">
          <img src={ProfileIcon} alt="Profile" />
        </Link> */}
        <Link
          to={"/"}
          onClick={() => {
            localStorage.removeItem("token");
            authContext.setAuthenticated(false);
          }}
          className="p-2 sidebar-icon rounded"
        >
          <img src={LogoutIcon} alt="Logout" className="h-[30px]"/>
        </Link>
      </div>
    </div>
  );
}
