import { useNavigate } from "react-router-dom";
import logoImgSrc from "./../images/logo.svg";
import logoTextImg from "./../images/main_text_logo.svg";
import { Typography } from "@mui/material";

export default function AppLogo() {
    const navigate = useNavigate();

    const logoContainerStyle: React.CSSProperties = {
        display: "flex",
        alignItems: "center",
        marginBottom: "16px",
        cursor: "pointer",
        width: "fit-content",
    };

    const logoStyle: React.CSSProperties = {
        width: "32px",
        height: "32px",
        marginRight: "8px",
    };

    return (
        <div style={logoContainerStyle} onClick={() => navigate("/")}>
            {/* <img src={logoImgSrc} alt="Logo" style={logoStyle} />
            <Typography
                variant="h4"
                component="h2"
                align="center"
                fontWeight="bold"
                style={{ color: "#E26834" }}
            >
                Firesync
            </Typography> */}
            <img src={logoTextImg} alt="Logo"/>
        </div>
    );
}
