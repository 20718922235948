import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import { Outlet, useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
export default function AppRoot() {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (authContext.authenticated === false) {
      navigate("/login");
    }
  }, [authContext.authenticated, navigate]);

  return (
    <div className="h-full">
      <Grid container className="h-full">
        <Grid item className="w-[80px]">
          <Sidebar />
        </Grid>
        <Grid item className="w-[calc(100%-80px)] bg-[#F3F3FA]">
          <Outlet />
        </Grid>
      </Grid>
    </div>
  );
}
