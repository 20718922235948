import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField, Button, Paper, Typography, Checkbox } from "@mui/material";
import { green } from "@mui/material/colors";
import CheckIcon from "@mui/icons-material/Check";
import { Link } from "react-router-dom";
import isExcludedEmail from "../utils/excludedDomains";
import api from "../utils/api";
import { useState } from "react";
import { PopupModal } from "react-calendly";
import AppLogo from "./LogoHeader";

interface DemoFormInput {
  fullname: string;
  email: string;
  company_name: string;
  integrations_interested_in?: string | undefined;
}

declare module "yup" {
  interface StringSchema {
    isNotExcludedEmail(msg?: string): this;
  }
}

function isNotExcludedEmail(this: yup.StringSchema, msg: string) {
  return this.test({
    name: "isNotExcludedEmail",
    message: msg,
    test: (value) => (value ? !isExcludedEmail(value) : false),
  });
}

yup.addMethod(yup.string, "isNotExcludedEmail", isNotExcludedEmail);

const demoFormSchema = yup
  .object({
    fullname: yup.string().required("Please enter your full name"),
    email: yup
      .string()
      .email("Invalid email")
      .required("Please enter your work email")
      .isNotExcludedEmail("is an excluded email domain"),
    company_name: yup.string().required("Please enter your company name"),
    integrations_interested_in: yup.string(),
  })
  .required();

export default function DemoPage() {
  const [submitting, setSubmitting] = useState(false);
  const [open, setOpen] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm<DemoFormInput>({
    defaultValues: {
      fullname: "",
      email: "",
      company_name: "",
      integrations_interested_in: "",
    },
    resolver: yupResolver(demoFormSchema),
  });

  async function signUp(data: DemoFormInput) {
    try {
      setSubmitting(true);

      await api({
        method: "POST",
        path: "users/signup",
        body: { user: data },
      });
      setOpen(true);
    } catch (error: any) {
      const responseBody = error.body;

      Object.keys(responseBody.status.message).forEach((key: any) => {
        setError(key, {
          type: "custom",
          message: `${key} ${responseBody.status.message[key][0]}`,
        });
      });
    } finally {
      setSubmitting(false);
    }
  }

  const loginPageStyle: React.CSSProperties = {
    display: "flex",
    height: "100vh",
  };

  const leftHalfStyle: React.CSSProperties = {
    backgroundColor: "#FFF2F2",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "2rem 5rem",
    width: "50%",
  };

  const rightHalfStyle: React.CSSProperties = {
    backgroundColor: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
  };

  const boxStyle: React.CSSProperties = {
    width: "32rem",
    height: "36rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "1rem",
  };

  const signInButtonStyle = {
    backgroundColor: "#E26834",
    marginTop: "16px",
  };

  const createAccountContainerStyle = {
    display: "flex",
    justifyContent: "center",
    fontSize: "0.7rem",
  };

  const footerTextStyle = {
    fontSize: "0.9rem",
    color: "darkgray",
  };

  const topics = [
    { id: 0, content: "A live demo of the API integration" },
    {
      id: 1,
      content:
        "A discussion on how our product can solve your business challenges",
    },
    { id: 2, content: "Benefits of using Unified API integration" },
    {
      id: 3,
      content:
        "A walkthrough on the features, integrations and any other queries",
    },
  ];

  const jk = document.createElement("div");

  return (
    <form onSubmit={handleSubmit(signUp)}>
      <div style={loginPageStyle}>
        <Paper elevation={0} style={leftHalfStyle}>
          <div className="flex flex-col w-full gap-16">
            <AppLogo />
            <div>
              <Typography
                variant="h3"
                component="p"
                align="left"
                fontWeight="bold"
              >
                Request a free demo Today!
              </Typography>

              <Typography
                variant="body2"
                component="p"
                align="left"
                style={{ marginTop: "2rem", marginBottom: "3rem" }}
              >
                Schedule a personalized 1:1 demo with our team of experts to
                discuss the features and functionalities of our product.
              </Typography>

              <Typography
                variant="h6"
                component="p"
                align="left"
                fontWeight="bold"
                style={{ marginTop: "10px", fontSize: "0.9rem" }}
              >
                Topics that will be addressed during the demo
              </Typography>
              <div>
                {topics.map((k) => (
                  <div
                    key={k.id}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Checkbox
                      checked={true}
                      style={{ color: green[500], padding: 0, margin: "4px 0" }}
                      icon={<span className="MuiCheckbox-icon" />}
                      checkedIcon={<CheckIcon />}
                    />
                    <Typography
                      variant="body2"
                      component="p"
                      style={{ marginLeft: "2px", fontSize: "small" }}
                    >
                      {k.content}
                    </Typography>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <Typography
            variant="body2"
            component="p"
            align="center"
            style={footerTextStyle}
          >
            All rights reserved
          </Typography>
        </Paper>
        <div style={rightHalfStyle}>
          <Paper elevation={3} style={boxStyle}>
            <div className="flex flex-col w-[75%] gap-6">
              <Typography
                variant="h5"
                component="span"
                fontWeight="bold"
                style={{ display: "flex", marginBottom: "1rem" }}
              >
                Get a demo
              </Typography>
              <TextField
                error={errors.fullname != null}
                helperText={errors.fullname?.message}
                label="Full Name"
                variant="outlined"
                fullWidth
                placeholder="Enter your fullname"
                {...register("fullname")}
              />
              <TextField
                error={errors.email != null}
                helperText={errors.email?.message}
                label="Work Email"
                variant="outlined"
                fullWidth
                placeholder="Enter your work email"
                {...register("email")}
              />
              <TextField
                error={errors.company_name != null}
                helperText={errors.company_name?.message}
                label="Company Name"
                variant="outlined"
                fullWidth
                placeholder="Enter your company name"
                {...register("company_name")}
              />
              <TextField
                label="Integrations you are interested in? (Optional)"
                variant="outlined"
                fullWidth
                {...register("integrations_interested_in")}
              />
              <Button
                disabled={submitting}
                variant="contained"
                fullWidth
                style={signInButtonStyle}
                type="submit"
              >
                Request demo
              </Button>
              <div style={createAccountContainerStyle}>
                <Typography variant="subtitle2" component="p" marginRight={1}>
                  Have an account?{" "}
                  <Link to="/login" style={{ color: "#4468E8" }}>
                    Sign in
                  </Link>
                </Typography>
              </div>
            </div>
          </Paper>
        </div>
      </div>
      <PopupModal
        open={open}
        onModalClose={() => setOpen(false)}
        url="https://calendly.com/ashwin-kmr29/15min"
        rootElement={document.getElementById("root") || jk}
      />
    </form>
  );
}
