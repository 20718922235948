import {
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import RequestApiGraph from "../components/RequestApiGraph";
import { useState, useEffect } from "react";
import PeopleIcon from "@mui/icons-material/People";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import WebhookIcon from "@mui/icons-material/Webhook";
import api from "../utils/api";

export default function ClientList() {
  const [graphData, setGraphData]: any = useState([]);
  const [data, setData] = useState([]);
  const [clientData, setClientData] = useState(0);
  const [totalCalls, setTotalCalls] = useState(0);

  const cards = [
    {
      text: "Active API",
      value: 7,
      logo: (
        <WebhookIcon
          style={{ color: "#03BA9F" }}
          className="mx-[1.3rem] my-[0.8rem]"
        />
      ),
      color: "#D1F2ED",
    },
    {
      text: "Total Clients",
      value: clientData,
      logo: (
        <PeopleIcon
          style={{ color: "#19A5E6" }}
          className="mx-[1.3rem] my-[0.8rem]"
        />
      ),
      color: "#D1EDFA",
    },
    {
      text: "Total API Requests",
      value: totalCalls,
      logo: (
        <TrendingUpIcon
          style={{ color: "#F0A70E" }}
          className="mx-[1.3rem] my-[0.8rem]"
        />
      ),
      color: "#FFF2D7",
    },
    {
      text: "Linked Integrations",
      value: 18,
      logo: (
        <InsertLinkIcon
          style={{ color: "#3E57F0" }}
          className="mx-[1.3rem] my-[0.8rem]"
        />
      ),
      color: "#DDDFEB",
    },
  ];

  const months = JSON.stringify([
    { month: "Jan", count: 0 },
    { month: "Feb", count: 0 },
    { month: "Mar", count: 0 },
    { month: "Apr", count: 0 },
    { month: "May", count: 0 },
    { month: "Jun", count: 0 },
    { month: "Jul", count: 0 },
    { month: "Aug", count: 0 },
    { month: "Sep", count: 0 },
    { month: "Oct", count: 0 },
    { month: "Nov", count: 0 },
    { month: "Dec", count: 0 },
  ]);

  async function getTransformedData(data: any) {
    return new Promise((resolve, reject) => {
      const newData = data.reduce((init: any, item: any) => {
        init[item.year] = init[item.year] || {
          graph: JSON.parse(months),
          calls: 0,
        };
        init[item.year].graph[item.month - 1].count += item.count;
        init[item.year].calls += item.count;
        setTotalCalls((prev) => prev + item.count);
        return init;
      }, {});
      const allData: any = Object.entries(newData);
      resolve(allData);
    });
  }

  async function getData() {
    const graphApi = await api({
      path: "api_metrics", method: 'GET'
    });
    const newData: any = await getTransformedData(graphApi.data);
    setGraphData(newData[newData.length - 1][1]);
    setData(newData);
  }

  async function getClientData() {
    const clientApi = await api({
      path: "clients", method: 'GET'
    });
    setClientData(clientApi.data.length);
  }

  useEffect(() => {
    getData();
    getClientData();
  }, []);

  const handleCreateNewItem = (e: any) => {
    e.preventDefault();
    setGraphData(data[Number(e.target.value)][1]);
  };

  return (
    <div className="h-full ml-12 pt-8 mr-12">
      <div className="page-header text-left">
        <div className="page-main-header text-3xl font-bold">
          Dashboard
        </div>
        <div className="page-main-header font-bold  mt-4">
          Welcome!
        </div>
        <div className="page-sub-header">
          Here's what's happening today.
        </div>
      </div>
      <div className="mt-6 flex gap-8">
        {cards.map((k: any) => (
          <Paper className="w-full p-6 !rounded-[10px]" key={k.text}>
            <div className="block text-left text-slate-500">{k.text}</div>
            <div className="flex justify-between items-center">
              <div className="text text-[#333333] font-semibold">{k.value}</div>
              <span
                style={{ backgroundColor: `${k.color}` }}
                className="rounded-[10px]"
              >
                {k.logo}
              </span>
            </div>
          </Paper>
        ))}
      </div>
      <div className="page-table mt-10">
        <Paper variant="outlined" className="p-10 !rounded-[10px]">
          <div className="flex justify-between">
            <div className="text-xl text-gray-700">API Requests</div>
            <FormControl size="small" className="rounded-[12px]">
              <InputLabel>Year</InputLabel>
              {data.length > 0 && (
                <Select
                  defaultValue={data.length - 1}
                  label="Year"
                  onChange={handleCreateNewItem}
                >
                  {data.map((yr, i) => (
                    <MenuItem key={yr[0]} value={i}>
                      {yr[0]}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FormControl>
          </div>
          <div className="my-5 text-right">
            Total Requests: {graphData.calls}
          </div>
          <RequestApiGraph height={250} width={1180} data={graphData} />
        </Paper>
      </div>
    </div>
  );
}
