import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const styleLoader = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 4,
  outline: "none",
  borderRadius: "0.5rem",
  p: 1,
};

export default function CommonLoader({ openLoader }: any) {
  return (
    <Modal open={openLoader}>
      <Box sx={styleLoader}>Please Wait... Loading</Box>
    </Modal>
  );
}
