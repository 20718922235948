import {
  TextField,
  Button,
  Paper,
  Typography,
  InputAdornment,
  IconButton,
  List,
  ListItem,
} from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import logoImgSrc from "./../images/logo.svg";
import api from "../utils/api";

interface SetPasswordInput {
  reset_password_token: string;
  password: string;
  password_confirmation: string;
}

const loginFormScema = yup
  .object({
    reset_password_token: yup.string().required(),
    password: yup
      .string()
      .required("Please enter your password")
      .min(6, "Password must be atleast 6 characters"),
    password_confirmation: yup
      .string()
      .required("Please confirm your password")
      .oneOf([yup.ref("password"), ""], "Passwords must match"),
  })
  .required();

function ResetPasswordPage() {
  const [showPassword, setShowPassword] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let [searchParams, _setSearchParams] = useSearchParams();

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<SetPasswordInput>({
    defaultValues: {
      reset_password_token: searchParams.get("") || "",
      password: "",
      password_confirmation: "",
    },
    resolver: yupResolver(loginFormScema),
    shouldUnregister: false,
  });

  useEffect(() => {
    setValue("reset_password_token", searchParams.get("token") || "");
  }, [searchParams, setValue]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  async function setPassword(data: SetPasswordInput) {
    try {
      setSubmitting(true);
      await api({ method: "PATCH", path: "password", body: data });
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  }

  const loginPageStyle: React.CSSProperties = {
    display: "flex",
    height: "100vh",
  };

  const leftHalfStyle: React.CSSProperties = {
    backgroundColor: "#FFF2F2",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "2rem 5rem",
    width: "50%",
  };

  const rightHalfStyle: React.CSSProperties = {
    backgroundColor: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
  };

  const boxStyle: React.CSSProperties = {
    width: "32rem",
    height: "32rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "1rem",
  };

  const signInButtonStyle = {
    backgroundColor: "#E26834",
    marginTop: "2rem",
  };

  const footerTextStyle = {
    fontSize: "0.9rem",
    color: "darkgray",
  };

  const logoContainerStyle: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    marginBottom: "16px",
  };

  const logoStyle: React.CSSProperties = {
    width: "32px",
    height: "32px",
    marginRight: "8px",
  };

  const leftHalfContent: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    maxWidth: "50%",
  };

  return (
    <form onSubmit={handleSubmit(setPassword)}>
      <div style={loginPageStyle}>
        <Paper elevation={0} style={leftHalfStyle}>
          <div className="flex flex-col w-full gap-16">
            <div style={logoContainerStyle}>
              <img src={logoImgSrc} alt="Logo" style={logoStyle} />
              <Typography
                variant="h4"
                component="h2"
                align="center"
                fontWeight="bold"
                style={{ color: "#E26834" }}
              >
                Firesync
              </Typography>
            </div>

            <div
              style={{
                display: "flex",
                height: "70vh",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={leftHalfContent}>
                <Typography
                  variant="h3"
                  component="p"
                  align="left"
                  fontWeight="bold"
                >
                  New password, new beginnings! Let's get started!
                </Typography>

                <Typography
                  variant="body2"
                  component="p"
                  align="left"
                  style={{ marginTop: "2rem", marginBottom: "3rem" }}
                >
                  Almost there! Type in your new password, and you're all set.
                  Here are three tips to create a strong password:
                  <List sx={{ listStyleType: "disc", pl: 4 }}>
                    <ListItem sx={{ display: "list-item", paddingLeft: "4px" }}>
                      Use a combination of letters, numbers, and symbols.
                    </ListItem>
                    <ListItem sx={{ display: "list-item", paddingLeft: "4px" }}>
                      Make it at least 8 characters long.{" "}
                    </ListItem>
                    <ListItem sx={{ display: "list-item", paddingLeft: "4px" }}>
                      Avoid using easily guessable information like birthdays or
                      common words.{" "}
                    </ListItem>
                  </List>
                </Typography>
              </div>
            </div>
          </div>
          <Typography
            variant="body2"
            component="p"
            align="center"
            style={footerTextStyle}
          >
            All rights reserved
          </Typography>
        </Paper>
        <div style={rightHalfStyle}>
          <Paper elevation={3} style={boxStyle}>
            <div className="flex flex-col w-[75%] gap-3">
              <Typography
                variant="h5"
                component="div"
                fontWeight="bold"
                style={{ display: "flex", marginBottom: "2rem" }}
              >
                Reset your password
              </Typography>
              <TextField
                error={errors.password != null}
                helperText={errors.password?.message}
                label="New Password"
                variant="outlined"
                type={showPassword ? "text" : "password"}
                fullWidth
                {...register("password")}
                InputProps={{
                  // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                error={errors.password_confirmation != null}
                helperText={errors.password_confirmation?.message}
                label="Confirm Password"
                variant="outlined"
                type="password"
                fullWidth
                {...register("password_confirmation")}
              />
              <Button
                variant="contained"
                type="submit"
                fullWidth
                disabled={submitting}
                style={signInButtonStyle}
              >
                Submit
              </Button>
            </div>
          </Paper>
        </div>
      </div>
    </form>
  );
}

export default ResetPasswordPage;
