import * as React from "react";
import Backdrop from "@mui/material/Backdrop";

export default function WidgetNavigation({ open, setOpen }: any) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
      onClick={handleClose}
    >
      <iframe
        src="https://api.firesyncapi.com/quick_books/authenticate"
        title="Quickbook"
        style={{ width: "40%", height: "90%" }}
      />
    </Backdrop>
  );
}
