import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Switch from "@mui/material/Switch";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/system";
import BasicModal from "./BasicModal";
import { Button } from "@mui/material";
import api from "../utils/api";
import CommonLoader from "./Loader";

interface Column {
  id: "software" | "status" | "linked_date" | "last_sync";
  label: string;
  minWidth?: number;
  align?: "center";
  format?: (value: number) => string;
}

const CustomSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-thumb": {
    backgroundColor: "#fff",
  },
  "& .MuiSwitch-track": {
    backgroundColor: grey[500],
  },
}));

const columns: readonly Column[] = [
  { id: "software", label: "Software", minWidth: 170, align: "center" },
  { id: "status", label: "Status", minWidth: 100, align: "center" },
  {
    id: "linked_date",
    label: "Linked Date",
    minWidth: 100,
    align: "center",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "last_sync",
    label: "Last Sync",
    minWidth: 100,
    align: "center",
  },
];

interface Data {
  id: number;
  software: string;
  status: boolean;
  linked_date: string;
  last_sync: string;
}

export default function StickyHeadTable({ details }: any) {
  const [clientId] = window.location.pathname.split("/").slice(-1);
  const [page, setPage] = React.useState(0);
  const [openLoader, setOpenLoader] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [tableRows, setTableRows] = React.useState<Data[]>([]);
  const [selectedItem, setSelectedItem] = React.useState<any>(null);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  async function getClients(Id: string) {
    try {
      const { client } = await api({
        path: "users/clients/" + Id,
        method: "GET",
      });
      const applist = client.included;
      const apps = [];
      for (let l = 0; l < applist.length; l++) {
        if (applist[l].type === "provider") {
          apps.push({
            id: applist[l].id,
            software: applist[l].attributes.name,
            status: applist.find(
              (p: any) => p?.attributes?.provider_id?.$oid === applist[l].id
            )?.attributes.enabled,
            linked_date: "",
            last_sync: "",
          });
        }
      }
      details(client.data.attributes);
      setTableRows(apps);
      setOpenLoader(false);
    } catch (e) {
      setOpenLoader(false);
    }
  }
  React.useEffect(() => {
    setOpenLoader(true);
    getClients(clientId);
  }, []);

  const handleToggleChange = (row: any) => {
    setSelectedItem(row);
    handleOpen();
  };

  const updateCheckAPI = () => {
    handleClose();
    setOpenLoader(true);
    api({
      path: "users/clients/" + clientId + "/activate_provider",
      method: "PATCH",
      body: { status: !selectedItem.status, provider_id: selectedItem.id },
    })
      .then(() => {
        setOpenLoader(false);
        const item: any = tableRows.find((h) => h.id === selectedItem.id);
        item.status = !selectedItem.status;
        setTableRows([...tableRows]);
      })
      .catch(() => {
        setOpenLoader(false);
      });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Paper
        sx={{ width: "100%", borderRadius: "10px", overflow: "hidden" }}
        elevation={3}
      >
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table>
            <TableHead component={Paper}>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    sx={{ fontWeight: "bold" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableRows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.software}
                    >
                      {columns.map((column) => {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.id === "status" ? (
                              <>
                                {row.status ? "Enabled" : "Disabled"}
                                <CustomSwitch
                                  checked={row.status}
                                  onChange={() => handleToggleChange(row)}
                                />
                              </>
                            ) : (
                              row[column.id]
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={tableRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <BasicModal open={open} handleClose={handleClose}>
        <div>
          Are you sure want to {selectedItem?.status && "de-"}activate
          Integration for {selectedItem?.software}?
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "1rem",
              marginTop: "1rem",
            }}
          >
            <Button size="small" onClick={handleClose}>
              No
            </Button>
            <Button variant="contained" size="small" onClick={updateCheckAPI}>
              Yes
            </Button>
          </div>
        </div>
      </BasicModal>
      <CommonLoader openLoader={openLoader} />
    </>
  );
}
