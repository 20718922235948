const Terms = () => {

  const terms = [
    {
      text: "Acceptance of Terms",
      body: "By accessing or using FireSync, you agree to be bound by these Terms, which constitute a legally binding agreement between you and FireSync. These Terms apply to all users of FireSync, including visitors, customers, and others who access or use the service."
    },
    {
      text: "Description of Service",
      body: "FireSync provides an API integration platform that enables users to connect with various software. The pricing structure for FireSync is based on a standard rate applicable to all users, subject to the number of API calls made in a month and the total number of integrations utilized."
    },
    {
      text: "Pricing and Payment",
      body: "The pricing for FireSync is determined based on the number of API calls made within a monthly billing cycle and the total number of integrations utilized by the user. The standard pricing details will be provided and are subject to change at our discretion. You will be billed according to the applicable pricing tier based on your usage."
    },
    {
      text: "User Responsibilities",
      body: "Users are responsible for maintaining the confidentiality of their account credentials and for all activities that occur under their account. Users agree to use FireSync in compliance with all applicable laws, regulations, and these Terms. Any unauthorised use or access to FireSync is strictly prohibited."
    },
    {
      text: "Modifications to Service and Terms",
      body: "FireSync reserves the right to modify, suspend, or discontinue any part of the service at any time without notice. We also reserve the right to update or revise these Terms at our discretion. We will make reasonable efforts to notify users in advance of any material changes to the service or these Terms that may significantly impact user access or usage. Continued use of FireSync after any modifications to the Terms signifies your acceptance of the updated Terms.",
      subBody: "This addition ensures that users will be informed in advance of any significant changes that may affect their usage of FireSync or their adherence to the Terms."
    },
    {
      text: "Termination",
      body: "We reserve the right to terminate or suspend access to FireSync for any user who violates these Terms or engages in any unauthorised or inappropriate use of the service."
    },
    {
      text: "Limitation of Liability",
      body: "FireSync shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or related to the use or inability to use FireSync."
    },
    {
      text: "Governing Law",
      body: "These Terms shall be governed by and construed in accordance with the laws of the United States, without regard to its conflict of law principles."
    }
  ]

  return (
    <div className="flex flex-col items-center">
      <div className="text-[2rem] underline m-8">FireSync Terms of Use</div>
      <div className="text-left w-[65%]">
        <div className="mb-6">
          These Terms of Use ("Terms") govern your access to and use of the FireSync platform provided by [Your Company Name] ("we," "us," or "FireSync"). By accessing or using FireSync, you agree to comply with these Terms. If you do not agree with these Terms, please refrain from using FireSync.
        </div>
        {terms.map((item, index) => <>
          <div className="my-4 p-0 text-[15px] font-semibold">
            {index + 1}. {item.text}
          </div>
          <div>
            {item.body}
          </div>
          {item.subBody && <div className="my-4">
            {item.subBody}
          </div>}
        </>
        )}
        <div className="my-8">
          By using FireSync, you acknowledge that you have read, understood, and agreed to these Terms of Use. If you have any questions or concerns regarding these Terms, please contact us.
        </div>
      </div>
    </div>
  );
};

export default Terms;
