import { Button, IconButton, InputBase, Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterIcon from "@mui/icons-material/FilterAlt";
import ClientTable from "../components/ClientTable";
import RightSliderForm from "../components/ClientForm";
import { useEffect, useState } from "react";
import React from "react";
import api from "../utils/api";
import CommonLoader from "../components/Loader";

export default function ClientList() {
  const [isSliderOpen, setSliderOpen] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);
  const [clients, setClients] = useState<Data[]>([]);
  const [clientObj, setClientObj] = useState(null);
  const [searchText, setSearchText] = useState("");

  // const handleToggleSlider = () => {
  //   setType({ edit: false, index: 0 });
  //   setSliderOpen((prev) => !prev);
  // };

  const createClient = () => {
    setClientObj(null);
    setSliderOpen(true);
  };

  interface Data {
    account_name: string;
    integration: string;
    no_of_requests: number;
    created_at: string;
    show: boolean;
  }

  const getChanged = (e: any) => {
    setSearchText(e.target.value);
    const val = e.target.value.toLowerCase();
    const newData = clients.map((j: Data) => {
      if (j.account_name.toLowerCase().includes(val)) {
        j.show = false;
      } else {
        j.show = true;
      }
      return j;
    });
    setClients([...newData]);
  };

  async function getClients() {
    try {
      const { clients } = await api({ path: "users/clients", method: "GET" });
      setClients(clients);
      setOpenLoader(false);
    } catch (e) {
      setOpenLoader(false);
    }
  }
  useEffect(() => {
    setOpenLoader(true);
    getClients();
  }, []);

  const reload = () => {
    getClients();
    setSearchText("");
  };

  const deleteRow = async (id: string) => {
    setOpenLoader(true);
    await api({
      path: "users/clients/" + id,
      method: "DELETE",
    })
      .then(() => {
        reload();
      })
      .catch(() => {
        reload();
      });
  };

  const createRow = async (data: any) => {
    setOpenLoader(true);
    await api({
      path: "users/clients",
      method: "POST",
      body: data,
    })
      .then(() => {
        reload();
      })
      .catch(() => {
        reload();
      });
  };

  const updateRow = async (id: string, data: any) => {
    setOpenLoader(true);
    await api({
      path: "users/clients/" + id,
      method: "PUT",
      body: data,
    })
      .then(() => {
        reload();
      })
      .catch(() => {
        reload();
      });
  };

  return (
    <>
      <div className="h-full ml-12 pt-8 mr-12">
        <div className="page-header text-left">
          <div className="page-main-header text-3xl font-bold">All Clients</div>
          <div className="page-sub-header mt-4">
            List of all your clients and integration details
          </div>
        </div>

        <div className="page-action flex mt-8 relative">
          <Paper
            variant="outlined"
            className="w-64 h-10"
            sx={{ borderRadius: "0.5rem" }}
          >
            <InputBase
              placeholder="Search"
              className="h-[100%]"
              value={searchText}
              onChange={getChanged}
              // inputProps={{ "aria-label": "Search" }}
              // sx={{ marginLeft: "0.40rem", fontSize: "0.95rem" }}
            />
            {/* <IconButton type="button">
            <SearchIcon />
          </IconButton> */}
          </Paper>
          {/* <Paper
          variant="outlined"
          className="mx-4 w-30"
          sx={{ borderRadius: "0.5rem" }}
        >
          <IconButton type="button">
            <FilterIcon
              sx={{ color: "#4468E8", fontSize: "1.25rem", fontWeight: "900" }}
            />
            <span className="text-base font-bold">Filter</span>
          </IconButton>
        </Paper> */}
          <Button
            variant="contained"
            className="w-32"
            sx={{
              position: "absolute",
              right: "1rem",
              textTransform: "none",
              backgroundColor: "#4468E8",
              borderRadius: "0.5rem",
            }}
            onClick={createClient}
          >
            New Client
          </Button>
          {isSliderOpen && (
            <RightSliderForm
              toggleState={setSliderOpen}
              state={isSliderOpen}
              clientObj={clientObj}
              createRow={createRow}
              updateRow={updateRow}
            />
          )}
        </div>
        <div className="page-table mt-8">
          <ClientTable
            toggleState={setSliderOpen}
            tableData={clients}
            setClientObj={setClientObj}
            deleteRowAPI={deleteRow}
          />
        </div>
      </div>
      <CommonLoader openLoader={openLoader} />
    </>
  );
}
