import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import { Backdrop, IconButton, Paper } from "@mui/material";
import infuit from "../images/widget/infuit.svg";
import xero from "../images/widget/xero.svg";
import microsoft from "../images/widget/microsoft.svg";
import sage from "../images/widget/sage.svg";
import netsuite from "../images/widget/netsuite.svg";
import odoo from "../images/widget/odoo.svg";
import zohobooks from "../images/widget/zohobooks.svg";
import mekari from "../images/widget/mekari.svg";
import WidgetNavigation from "../components/WidgetNavigation";

function Widget() {
  const [value, setValue] = useState(0);
  const [openWidget, setCloseWidget] = useState(true);
  const [openURL, setOpenURL] = useState(false);
  const tabs = ["Accounting", "HRIS", "CRM", "Collaboration", "More"];

  const cards = [
    infuit,
    xero,
    microsoft,
    sage,
    netsuite,
    odoo,
    zohobooks,
    mekari,
  ];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  //const navigate = useNavigate();

  useEffect(() => {}, []);

  const mainContainer: React.CSSProperties = {
    borderRadius: "1rem",
    width: "60%",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "85%",
  };

  const header: React.CSSProperties = {
    fontSize: "1.7rem",
    fontWeight: "500",
    padding: "2rem 0",
    color: "#333333",
  };

  const topContainer: React.CSSProperties = {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 3rem",
  };

  const cardContainer: React.CSSProperties = {
    position: "relative",
    flex: "1 1 auto",
    width: "90%",
  };

  const cardWrapper: React.CSSProperties = {
    height: "calc(100% - 7rem)",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    gap: "1rem",
    flexWrap: "wrap",
  };
  const cardBlock: React.CSSProperties = {
    position: "relative",
    width: "23%",
    marginBottom: "0.5rem",
    borderRadius: "1rem",
  };
  const imgWrapper: React.CSSProperties = {
    position: "relative",
    height: "calc(100% - 2rem)",
  };
  const imgBlock: React.CSSProperties = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    height: "60%",
  };

  const cardBottom: React.CSSProperties = {
    fontSize: "smaller",
    position: "absolute",
    background: "#F5F5F5",
    bottom: "0",
    width: "100%",
    color: "#E26834",
    height: "2rem",
    lineHeight: "2rem",
    cursor: "pointer",
    borderRadius: "0 0 1rem 1rem",
  };

  const mainCardFooter: React.CSSProperties = {
    color: "#8A8A8A",
    fontSize: "x-small",
    position: "absolute",
    bottom: "0.6rem",
    width: "100%",
  };

  const closeWidget = () => {
    (window as any).top.postMessage("closeWidget", "*");
    setCloseWidget(false);
  };

  const navigateTo = (index: number) => {
    if(index === 0){
      setOpenURL(true);
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openWidget}
      >
        <div style={mainContainer}>
          <div style={topContainer}>
            <div></div>
            <div style={header}>Integrations</div>
            <IconButton onClick={closeWidget}>
              <CloseIcon style={{ fontSize: "xx-large" }} />
            </IconButton>
          </div>
          <div style={cardContainer}>
            <Box
              sx={{
                borderBottom: 1,
                marginBottom: "1.5rem",
                marginTop: "-1rem",
              }}
            >
              <Tabs value={value} onChange={handleChange}>
                {tabs.map((t, i) => (
                  <Tab
                    label={t}
                    key={t}
                    className="!normal-case"
                    disabled={i > 0}
                  />
                ))}
              </Tabs>
            </Box>
            {value === 0 && (
              <Box style={cardWrapper}>
                {cards.map((k, m) => (
                  <Paper elevation={3} style={cardBlock} key={m}>
                    <div style={imgWrapper}>
                      <img src={k} style={imgBlock} alt="card" />
                    </div>
                    <div style={cardBottom} onClick={() => navigateTo(m)}>
                      Connect
                    </div>
                  </Paper>
                ))}
              </Box>
            )}
            <div style={mainCardFooter}>Powered By Firesync</div>
          </div>
        </div>
      </Backdrop>
      <WidgetNavigation open={openURL} setOpen={setOpenURL} />
    </>
  );
}

export default Widget;
