import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const columns: readonly string[] = ["Applications", "Status", "Manage"];

interface Data {
  application: string;
  status: string;
}

function createData(application: string, status: string): Data {
  return { application, status };
}

const rows = [
  createData("Xero", "Live"),
  createData("Quickbooks", "Live"),
  createData("Jurnal", "Live"),
  createData("Tally Desktop", "In progress"),
  createData("Zohobooks", "Unavailable"),
  createData("MS Dynamics", "Unavailable"),
];

export default function StickyHeadTable({
  toggleState,
  toggleEdit,
  setName,
}: any) {
  const liveSettings: React.CSSProperties = {
    border: "solid 1px #4468E8",
    padding: "1px 8px",
    borderRadius: "4px",
    fontSize: "small",
    color: "#4468E8",
    cursor: "pointer",
  };

  const nonLiveSettings: React.CSSProperties = {
    backgroundColor: "#DADADA",
    border: "solid 1px #DADADA",
    padding: "1px 8px",
    borderRadius: "4px",
    fontSize: "small",
    color: "#4468E8",
    pointerEvents: "none"
  };
  const openDrawer = (name: string) => {
    setName(name);
    toggleState(true);
    //below to open edit pop up
    //toggleEdit(true);
  };

  return (
    <Paper
      sx={{ width: "60%", overflow: "hidden", borderRadius: "10px" }}
      elevation={3}
    >
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table>
          <TableHead component={Paper}>
            <TableRow>
              {columns.map((column, i) => (
                <TableCell key={column}>
                  <span className="font-semibold ml-6">{column}</span>
                  {i === 0 && (
                    <span className="bg-[#FFEFE8] ml-1 px-1.5 py-0.5 rounded-[3px] font-semibold">
                      {rows.length}
                    </span>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => {
              return (
                <TableRow key={row.application}>
                  {columns.map((column, n) => {
                    return (
                      <TableCell key={column}>
                        {n === 0 && (
                          <span className="ml-6 text-[#333] font-medium">
                            {row.application}
                          </span>
                        )}
                        {n === 1 && (
                          <span
                            className="ml-6"
                            style={{
                              color:
                                row.status === "Live" ? "#247C0D" : "#706565",
                            }}
                          >
                            {row.status}
                          </span>
                        )}
                        {n === 2 && (
                          <span
                            className="ml-6" style={row.status === "Live" ? liveSettings : nonLiveSettings}
                            onClick={() => openDrawer(row.application)}
                          >
                            Settings
                          </span>
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
