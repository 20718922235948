import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

interface DayWiseApi {
  data: number[];
  width: number;
  height: number;
}

export default function RequestApiGraph(props: any) {
  return (
    <div>
      <ResponsiveContainer height={props.height}>
        <LineChart
          data={props.data.graph}
          width={props.width}
          height={props.height}
          margin={{ top: 5, right: 0, bottom: 5, left: 0 }}
        >
          <Line type="monotone" dataKey="count" stroke="#8884d8" />
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
