import { useState, useEffect } from "react";
import { Button, Paper, TextField } from "@mui/material";
import {
  FileCopyOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import api from "../utils/api";
export default function DeveloperKey() {
  const [showPasswordprod, setShowPasswordprod] = useState(false);
  const [showPasswordsand, setShowPasswordsand] = useState(false);
  const handleCopyClick = () => {
    // Logic to copy the text
    // You can use the Clipboard API or a third-party library for this functionality
  };

  const [keys, setKeys] = useState(['', ''])

  async function getKeys() {
    const response = await api({ path: 'api_keys', method: 'GET' })
    setKeys(response.map((data: { key: any; }) => data.key))
  }

  useEffect(() => {
    getKeys()
  }, [])

  const handleTogglePasswordprodVisibility = () => {
    setShowPasswordprod((prevShowPasswordprod) => !prevShowPasswordprod);
  };

  const handleTogglePasswordsandVisibility = () => {
    setShowPasswordsand((prevShowPasswordsand) => !prevShowPasswordsand);
  };

  return (
    <div className="h-full ml-12 mr-12 pt-8">
      <div className="page-header text-left">
        <div className="page-main-header text-3xl font-bold">
          Developer API Keys
        </div>
        <div className="page-sub-header mt-4">
          API Keys for Production and Sandbox Access
        </div>
      </div>

      <div className="page-content flex mt-8">
        <div className="page-table w-3/4">
          <Paper sx={{ width: "100%" , paddingBottom: "6rem", borderRadius: '10px' }} elevation={3}>
            <div className="font-semibold text-left px-12 py-4">Authentication Keys</div>
            <hr />
            <div className="mx-12 my-8">
              <div
                className="text-left text-[#333333] mx-0 my-2"
              >
                Production Access Key
              </div>
              <div className="text-left text-[#706565] text-[small]">
                To interact with Firesync API with production environment, Utilize this key.
              </div>
              <div className="flex mx-0 my-2">
                <TextField
                  variant="outlined"
                  size="small"
                  value={keys[0]}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <Button size="small" onClick={handleCopyClick}>
                        <FileCopyOutlined fontSize="small" />
                      </Button>
                    ),
                    startAdornment: (
                      <Button
                        size="small"
                        onClick={handleTogglePasswordprodVisibility}
                      >
                        {showPasswordprod ? (
                          <VisibilityOff fontSize="small" />
                        ) : (
                          <Visibility fontSize="small" />
                        )}
                      </Button>
                    ),
                  }}
                  type={showPasswordprod ? "text" : "password"}
                  sx={{ width: "400px", borderColor: "blue", }}
                />
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{
                    padding: "6.5px",
                    backgroundColor: "white",
                    color: "blue",
                    borderColor: "blue",
                    textTransform: 'none',
                    marginLeft: "20px",
                  }}
                  onClick={async () => {
                    await api({ method: "POST", path: "api_keys", body: { env: "prod" } })
                    getKeys()
                  }}
                >
                  Generate New API Key
                </Button>
              </div>
              <div className="mt-12">
                <div
                  className="text-left text-[#333333] mx-0 my-2"
                >
                  Sandbox Access Key
                </div>
                <div className="text-left text-[#706565] text-[small]">
                  Test your application with Firesync API sandbox keys.
                </div>
                <div className="flex mx-0 my-2">
                  <TextField
                    variant="outlined"
                    size="small"
                    value={keys[1]}
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <Button size="small" onClick={handleCopyClick}>
                          <FileCopyOutlined fontSize="small" />
                        </Button>
                      ),
                      startAdornment: (
                        <Button
                          size="small"
                          onClick={handleTogglePasswordsandVisibility}
                        >
                          {showPasswordsand ? (
                            <VisibilityOff fontSize="small" />
                          ) : (
                            <Visibility fontSize="small" />
                          )}
                        </Button>
                      ),
                    }}
                    type={showPasswordsand ? "text" : "password"}
                    sx={{ width: "400px" }}
                  />
                  <Button
                    variant="outlined"
                    color="primary"
                    sx={{
                      padding: "6.5px",
                      backgroundColor: "white",
                      color: "blue",
                      borderColor: "blue",
                      textTransform: 'none',
                      marginLeft: "20px",
                    }}
                    onClick={async () => {
                      await api({ method: "POST", path: "api_keys", body: { env: "sandbox" } })
                      getKeys()
                    }}
                  >
                    Generate New API Key
                  </Button>
                </div>
              </div>
            </div>
          </Paper>
        </div>
      </div>
    </div>
  );
}
