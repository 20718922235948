const Privacy = () => {

  return (
    <div className="flex flex-col items-center">
      <div className="text-[2rem] underline m-8">Privacy Policy</div>
      <div className="text-left w-[65%]">
        <div className="text-[15px] font-semibold mx-0 my-6">Privacy Policy Overview:</div>
        <div className="mb-6">
          At Firesync, we are committed to safeguarding the privacy and security of our users' data. Our platform specialises in seamless API integrations, specifically focusing on accounting software connections via established API endpoints.
        </div>
        <ol style={{ listStyle: 'auto' }} className="m-4 p-0 text-[15px] font-semibold">
          <li className="mb-8">Data Collection:
            <ul style={{ listStyle: 'disc' }} className="ml-12 p-0 text-[medium] font-normal">
              <li>We collect and store limited user information necessary for account creation, authentication, and API integration setup.</li>
              <li>This includes basic personal details and authentication tokens required for linking with accounting software APIs.</li>
            </ul>
          </li>
          <li className="mb-8">Data Usage:
            <ul style={{ listStyle: 'disc' }} className="ml-12 p-0 text-[medium] font-normal">
              <li>The information collected is solely used to facilitate API integrations and enhance user experience on our platform.</li>
              <li>We do not share, sell, or disclose user data to third parties without explicit consent, except where legally required.</li>
            </ul>
          </li>
          <li className="mb-8">Data Security:
            <ul style={{ listStyle: 'disc' }} className="ml-12 p-0 text-[medium] font-normal">
              <li>We encrypt sensitive information such as client names and email IDs within our database using industry standard encryption protocols.</li>
              <li>Our platform employs robust security measures to protect user data from unauthorised access, breaches, or misuse.</li>
            </ul>
          </li>
          <li className="mb-8">Cookies and Tracking:
            <ul style={{ listStyle: 'disc' }} className="ml-12 p-0 text-[medium] font-normal">
              <li>We use cookies and similar technologies to enhance user experience, track usage patterns, and improve our services.</li>
              <li>Users have the option to manage cookie preferences through their browser settings.</li>
            </ul>
          </li>
          <li className="mb-8">User Control and Rights:
            <ul style={{ listStyle: 'disc' }} className="ml-12 p-0 text-[medium] font-normal">
              <li>Users retain control over their data and can request access, modification, or deletion of their information stored on our platform.</li>
              <li>We respect users' rights regarding their data and strive to facilitate these requests promptly.</li>
            </ul>
          </li>
          <li className="mb-8">Updates and Notifications:
            <ul style={{ listStyle: 'disc' }} className="ml-12 p-0 text-[medium] font-normal">
              <li>Our privacy policy may undergo periodic updates to align with evolving practices or regulatory changes.</li>
              <li>Users will be duly notified of any significant policy changes that might impact their data privacy.</li>
            </ul>
          </li>
        </ol>
        <div>
          By using Firesync API Integrations, users agree to abide by the terms outlined in this privacy policy. We prioritise transparency and user trust, striving to maintain the highest standards of data protection.
        </div>
        <div className="my-8">
          This addition emphasises the encryption practices implemented to secure sensitive data within the platform's database.
        </div>
      </div>
    </div>
  );
};

export default Privacy;
