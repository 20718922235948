import errorIcon from '../images/error_icon.svg';

export default function ErrorBox({ message }: any) {

  const mainErrorBox: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '10px',
    backgroundColor: '#DD8C8780',
    height: '56px',
    border: 'solid 1.5px #B43220'
  };

  const errorText: React.CSSProperties = {
    marginRight: '0.5rem',
    color: '#B72727',
    fontWeight: 'bold'
  };

  const errorMessage: React.CSSProperties = {
    fontSize: 'small',
    textAlign: 'left',
    lineHeight: '16px'
  };

  return (
    <div style={mainErrorBox}>
      <img src={errorIcon} alt="error" style={{ margin: '0 1rem' }} />
      <div style={errorText}>Error!</div>
      <div style={errorMessage}>{message}</div>
    </div>
  );
}
